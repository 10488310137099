<template>
  <Portlet
    :title="`${ weeklyReport ? 'Weekly' : 'Daily' } Total Runs`"
    icon="chart-area"
    class="runChart"
  >
    <template
      v-if="mobileMixin_isMobile == false"
      slot="buttons"
    >
      <toggle-button
        v-model="weeklyReport"
        :labels="{ checked: 'weekly', unchecked: 'daily' }"
        :width="80"
        :height="30"
        :font-size="12"
        :color="{ checked: 'rgb(117, 199, 145)', unchecked: '#30a7df' }"
        class="m-0"
        :sync="true"
      />
    </template>

    <LoadingPlaceholder v-if="loading" />
    <template v-else>
      <div class="m-form__group form-group">
        <div class="m-radio-inline">
          <label class="m-radio">
            <input
              v-model="days"
              type="radio"
              :name="'mediaReportChartRadios' + spotId"
              value="365"
            >1 {{ $t('year') }}
            <span />
          </label>
          <label class="m-radio">
            <input
              v-model="days"
              type="radio"
              :name="'mediaReportChartRadios' + spotId"
              value="182"
            ><sup>1</sup>/<sub>2</sub> a {{ $t('year') }}
            <span />
          </label>
          <label class="m-radio">
            <input
              v-model="days"
              type="radio"
              :name="'mediaReportChartRadios' + spotId"
              value="92"
            >3 {{ $t('month') }}
            <span />
          </label>
          <label class="m-radio">
            <input
              v-model="days"
              type="radio"
              :name="'mediaReportChartRadios' + spotId"
              value="21"
            >3 {{ $t('weeks') }}
            <span />
          </label>
        </div>
      </div>

      <template v-if="reportsAvailable">
        <kendo-chart
          ref="chart"
          :category-axis-categories="categories"
          :legend-position="'top'"
          :chart-area-background="''"
          :series-defaults-type="'line'"
          :series-defaults-style="'smooth'"
          :value-axis-labels-format="'{0}'"
          :value-axis-line-visible="false"
          :value-axis-axis-crossing-value="'-10'"
          :category-axis-major-grid-lines-visible="false"
          :category-axis-labels-rotation="'auto'"
          :tooltip-visible="true"
          :tooltip-format="'{0}'"
          :tooltip-template="tooltiptemplate"
          :theme="'sass'"
        >
          <kendo-chart-series-item
            :name="installationId"
            :data="altData"
          />
        </kendo-chart>
      </template>
      <template v-else>
        {{ $t('noDataAvailable') }}
      </template>
    </template>
  </Portlet>
</template>

<script>
import { dateTimeMixin } from '@/mixins/dateTimeMixin.js';
import { mobileMixin } from '@/mixins/mobileMixin.js';
import { responsiveKendoGridMixin } from '@/mixins/responsiveKendoGridMixin.js';

export default {
  name: "RunChart",
  mixins: [
    dateTimeMixin,
    mobileMixin,
    responsiveKendoGridMixin
  ],
  props: {
    installationId: {
      type: String,
      required: true
    },
    spotId: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      loading: true,
      reports: null,
      tooltiptemplate: '#= series.name #: #= value #',
      altData: null,
      categories: null,
      days: 21,
      weeklyReport: false,
      hasChangedDaily: false
    }
  },
  computed: {
    reportsAvailable: function () {
      if (this.altData && this.altData.length > 0) {
        return true;
      }
      return false;
    }
  },
  watch: {
    days: function (val) {
      if(val != 21 && !this.hasChangedDaily) {
        this.weeklyReport = true;        
      }
      else if(val == 21 && !this.hasChangedDaily){
        this.weeklyReport = false;        
      }
      this.getReports();
    },
    weeklyReport: function (val) {
      if((val && this.days == 21) || (!val && this.days != 21)) {
        this.hasChangedDaily = true;
      } 
      this.getReports();
    }
  },
  created () {
    this.getReports();
    window.addEventListener('resize', this.handleResize);
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    handleResize () {
      this.responsiveKendoGridMixin_refresh(this.$refs.chart);
    },
    getReports () {
      if (this.weeklyReport) {
        this.getWeeklyReports();
      } else {
        this.getDaylyReports();
      }
    },
    getDaylyReports () {
      this.axios.get(`/Media/GetReportWithSpotId/${ this.installationId }/${ this.days }/${ this.spotId }`)
        .then((response) => {
          this.reports = response.data;
          this.altData = this.reports.map(o => o.count);
          this.categories = this.reports.map(o => this.$options.filters.dateTime_date(o.reportDate));
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    getWeeklyReports () {
      this.axios.get(`/Media/GetWeeklyWithSpotIdReport/${ this.installationId }/${ this.days }/${ this.spotId }`)
        .then((response) => {
          this.reports = response.data;
          this.altData = this.reports.map(o => o.count);
          this.categories = this.reports.map(o => this.$options.filters.dateTime_date(o.date));
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    }
  }
}
</script>